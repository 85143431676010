.loadingBG {
    width: 100vw;
    height: 100vh;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: auto;
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    background-image: url(../images/grass-bg.png);
    background-size: cover;
    justify-content: center;
    align-items: center;

    &.noBg {
        background-color: unset;
    }

    .animContainer {
        height: 40%;
    }



    .text {
        font-size: 3.75vh;
        text-align: center;
        margin-top: 7vh;
        color: #E56DA6;
        font-family: "Metallophile";
    }

    .progressBar {
        height: fit-content;
        max-width: 95%;
        min-height: 5vh;
        width: 50vh;
        background-color: #e0e0de;
        border-radius: 5vh;
        margin: 1vh;
        border: solid black 0.5vh;
        position: relative;

        .fill {
            height: 100%;
            background-color: #E56DA6;
            border-radius: inherit;
            text-align: right;
            height: 100%;
        }

        .label {
            padding: 0.5vh;
            width: fit-content;
            color: white;
            font-weight: bold;
            margin: auto;
            font-size: 2vh;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }
    }
}
