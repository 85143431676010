.tutorialBG {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    transition: 0.5s;
    background-color: rgba(0,0,0,0);
    z-index: 1001;

    &.show {
        background-color: rgba(0,0,0,0.5);
    }

    .tutorial {
        height: 65%;
        width: 60%;
        margin: auto;
        transition: 1s;
        transform: translateY(-100vh);
        border: 2vh solid #E36FA6;
        border-radius: 2vh;
        background-color: #E36FA6;

        &.show {
            transform: translateY(0);
        }
    }

    .skipCount {
        font-family: "Metallophile";
        font-size: 6vh;
        color: white;
        height: fit-content;
        padding: 5vh;
        margin: auto;
        transition: 1s;
        transform: translateY(75vh);

        &.show {
            transform: translateY(0);
        }
    }
}
