.player {
    width: 90%;
    height: fit-content;
    margin: auto;
   /* margin-bottom: 5.5vh;
    margin-top: 0vh;*/
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    position: relative;

    &.reverse {
        flex-direction: row-reverse;
    }

    .playerSection {
        height: 100%;
        width: fit-content;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        position: relative;

        .hat {
            position: absolute;
            height: 7vh;
            left: 0;
            right: 0;
            top: -2vh;
            margin: auto;
        }
    }

    .potato {
        height: 14vh;
        margin: auto;

        &.hidden {
            opacity: 0;
        }
    }

    .textSection {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-Top: -1vh;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        position: relative;

        .nameText {
            font-size: 3vh;
            margin: auto;
            font-family: "Metallophile";
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
            text-align: center;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 100%;

            &.large {
                font-size: 3vh;
            }

            &.medium {
                font-size: 2vh;
            }

            &.small {
                font-size: 1.65vh;
            }

            &.wrangler {
                background-color: #6169A9;
                border-radius: 2vh;
                padding-left: 2vh;
                padding-right: 2vh;
                color: white;
            }
        }
    }

    &:nth-child(1) {
        .boxSection {
            .bg {
                content: url(../images/PlayerBoxes/player-box-1.png);

                &.pink {
                    content: url(../images/PlayerBoxes/player-box-1-pink.png);
                }
            }
        }
    }

    &:nth-child(2) {
        .boxSection {
            .bg {
                content: url(../images/PlayerBoxes/player-box-2.png);

                &.pink {
                    content: url(../images/PlayerBoxes/player-box-2-pink.png);
                }
            }
        }
    }

    &:nth-child(3) {
        .boxSection {
            .bg {
                content: url(../images/PlayerBoxes/player-box-3.png);

                &.pink {
                    content: url(../images/PlayerBoxes/player-box-3-pink.png);
                }
            }
        }
    }

    &:nth-child(4) {
        .boxSection {
            .bg {
                content: url(../images/PlayerBoxes/player-box-4.png);

                &.pink {
                    content: url(../images/PlayerBoxes/player-box-4-pink.png);
                }
            }
        }
    }

    &:nth-child(5) {
        .boxSection {
            .bg {
                content: url(../images/PlayerBoxes/player-box-5.png);

                &.pink {
                    content: url(../images/PlayerBoxes/player-box-5-pink.png);
                }
            }
        }
    }

    &:nth-child(6) {
        .boxSection {
            .bg {
                content: url(../images/PlayerBoxes/player-box-6.png);

                &.pink {
                    content: url(../images/PlayerBoxes/player-box-6-pink.png);
                }
            }
        }
    }

    &:nth-child(7) {
        .boxSection {
            .bg {
                content: url(../images/PlayerBoxes/player-box-7.png);

                &.pink {
                    content: url(../images/PlayerBoxes/player-box-7-pink.png);
                }
            }
        }
    }

    &:nth-child(8) {
        .boxSection {
            .bg {
                content: url(../images/PlayerBoxes/player-box-8.png);

                &.pink {
                    content: url(../images/PlayerBoxes/player-box-8-pink.png);
                }
            }
        }
    }

    .boxSection {
        width: 32vh;
        height: 14.5vh;
        display: flex;
        position: relative;
        margin-top: auto;
        margin-bottom: auto;
        transition: 0.5s;
        transform: scale(1);

        .anchor {
            position: absolute;
            margin: auto;
            width: 0;
            height: 0;
            left: 25%;
            top: 25%;
        }

        &.confirmed {
            transform: scale(0.8);
        }

        .bg {
            width: 100%;
            height: 110%;
            position: absolute;
            z-index: 2
            /*filter: drop-shadow(2px 4px 0px black);*/
        }

        .pinkCow {
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            z-index: 1;
            height: 90%;
            transition: 0.5s;

            &.show {
                bottom: 7vh;
                opacity: 1;
            }
        }

        .answeredText {
            top: 0;
            margin: auto;
            position: absolute;
            opacity: 0;
            z-index: 3;

            &.right {
                right: -1vh;
                transform: rotate( 30deg);
            }

            &.left {
                left: -1vh;
            }

            &.show {
                opacity: 1;
            }
        }

        .mooBubble {
            width: 12vh;
            position: absolute;
            z-index: 4;
            margin: auto;

            &.left {
                left: 0;
            }

            &.right {
                right: 0;
            }

            &.show {
                display: initial;
            }
        }

        .answerText {
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
            height: fit-content;
            top: 0;
            bottom: 0;
            z-index: 2;
            font-size: 4vh;
            font-family: "Metallophile";
            font-weight: 700;
            text-align: center;
            color: white;
            padding: 2vh;
            overflow-wrap: break-word;
            transition: 0.5s;
            opacity: 0;

            &.show {
                opacity: 1;
            }

            &.selected {
                color: #69BFAB;
            }

            &.selectedPink {
                color: #E56DA6;
            }

            &.large {
                font-size: 4vh;
            }

            &.medium {
                font-size: 3vh;
            }

            &.small {
                font-size: 2vh;
            }
        }

        .scoreSection {
            width: 25vh;
            height: 10vh;
            display: flex;
            flex-wrap: wrap;
            margin: auto;
            z-index: 2;
            position: relative;
            transition: 0.25s;
            opacity: 1;

            &.right {
                flex-direction: row-reverse;
                margin-right: unset;
                padding-right: 1vh;
            }

            &.left {
                flex-direction: row;
                margin-left: unset;
                padding-left: 1vh;
            }

            &.hidden {
                opacity: 0;
            }

            .cowToken {
                height: 4.5vh;
                margin: 0.5vh;

                &.fade {
                    opacity: 0.5;
                }
            }

            .scoreContainer {
                position: absolute;
                top: 1vh;
                bottom: 0;
                margin: auto;
                background-color: black;
                border-radius: 50%;
                display: flex;
                width: -moz-fit-content;
                width: 10vh;
                height: -moz-fit-content;
                height: 10vh;

                .scoreText {
                    font-size: 8vh;
                    text-align: center;
                    margin: auto;
                    font-family: "DearType";
                    color: white;
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0.7vh;
                    height: 100%;
                }

                &.right {
                    left: -10vh;
                }

                &.left {
                    right: -10vh;
                }
            }
        }
        /*.pen {
            width: 90%;
            height: 80%;
            margin: auto;
            border-radius: 1vh;
            position: relative;

            .cow {
                position: absolute;
                height: 3.5vh;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                transition: all 1s linear;

                &.pink {
                    height: 8vh;
                }
            }
        }*/
    }
}
