body {
    overflow: hidden;
    height: 100%;
    margin: 0;
}


div {
    /*transition-property: position;*/
    /*transition: all 1s ease-in-out;*/
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

.logoSection {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: fit-content;
    height: fit-content;
    display: flex;
    transform: scale(1);
    transition: 1s;

    &.corner {
        top: -80vh;
        left: -80vw;
        transform: scale(0.4);
        /*.logo {
            width: 26vh !important;
        }*/
    }

    .logo {
        width: 65vh;
        margin: auto;
    }
}

.gameContainer {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    /*background-color: white;*/
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: hidden;
    background-image: url(../images/grass-bg.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    .loadingContainer {
        position: absolute;
        left: 0;
        right: 0;
        /*top: 0;*/
        /* bottom: 0; */
        margin: auto;
        max-width: 700px !important;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        z-index: 1;
    }


    .counterSection {
        width: 40%;
        height: fit-content;
        margin: auto;
        display: flex;
        flex-direction: row;
        margin-bottom: 1vh;
        transition: 1s;
        position: absolute;
        left: 0;
        right: 0;
        transform: translateY(0);

        &.hidden {
            transform: translateY(-12vh);
        }

        .counterText {
            text-align: center;
            font-size: 5vh;
            font-family: "KCHand";
            margin-left: auto;
        }

        .tickList {
            width: fit-content;
            margin-right: auto;
            display: flex;
        }
    }

    .roomCode {
        position: absolute;
        right: 3vh;
        top: 1vh;
        margin: auto;
        display: flex;
        flex-direction: row;

        .textBox {
            display: flex;
            flex-direction: column;
            margin: auto;
            width: fit-content;
            height: fit-content;

            .text {
                text-align: end;
                font-size: 3.5vh;
                font-family: "Metallophile";
                /*font-weight: bold;*/

                &:nth-child(1) {
                    transform: translateY(0.75vh);
                }

                &:nth-child(2) {
                    transform: translateY(-0.75vh);
                }

                &.code {
                    font-family: "KCHand";
                    font-size: 4vh;
                    font-weight: normal;
                }
            }

            .iconsBox {
                margin-left: auto;
                margin-top: auto;
                margin-bottom: auto;
                display: flex;
                flex-direction: row;
                width: fit-content;
                height: fit-content;
                z-index: 1000;

                .muteToggle {
                    width: fit-content;
                    height: fit-content;
                    position: relative;
                    margin: auto;
                    cursor: pointer;
                    padding-left: 1vh;

                    .muteIcon {
                        width: auto;
                        height: 5vh;
                    }
                }
            }
        }

        .qrCodeBox {
            background-color: white;
            width: fit-content;
            height: fit-content;
            margin: auto;
            padding: 1vh;
            display: flex;
            border-radius: 1vh;
            margin-left: 1vh;
            margin-top: 1vh;

            .qrCode {
                height: 14vh !important;
                width: 14vh !important;
                margin: auto;
            }
        }
    }

    .notification {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: fit-content;
        height: 50vh;
        display: flex;
        flex-direction: row;
        z-index: 20;

        &.show {
            transition: 1.5s ease-out;
            transform: translateY(0);
        }

        &.hide {
            transition: 1.5s ease-in;
            transform: translateY(100vh);
        }

        .notificationBg {
            width: 100%;
            height: 100%;
            position: absolute;
        }

        .wranglerSection {
            position: relative;
            display: flex;
            flex-direction: row;
            margin: auto;
            width: fit-content;
            height: fit-content;
            transform: translate(-10vh, -2vh);

            .wranglerPotato {
                margin: auto;
                height: 45vh;
                z-index: 1;
                transform: rotate( -10deg) translate(2vh, -3vh);
            }

            .textBox {
                margin: auto;
                display: flex;
                flex-direction: column;
                z-index: 1;
                transform: translateX(-3vh) rotate(5deg);

                .wranglerName {
                    margin: auto;
                    font-size: 13vh;
                    text-align: center;
                    font-family: "KCHand";
                    color: white;
                    transform: translateY(4vh ) rotate(-9deg);
                }

                .wranglerText {
                    margin: auto;
                    font-size: 5.5vh;
                    text-align: center;
                    font-weight: 600;
                    color: white;
                    font-family: "Metallophile";
                    transform: translateX(3vh ) rotate(-6deg);
                    line-height: 1;
                }
            }
        }

        .pickPlayers {
            position: relative;
            display: flex;
            flex-direction: column;
            margin: auto;
            width: fit-content;
            height: fit-content;

            .wranglerBox {
                position: relative;
                margin: auto;
                width: fit-content;
                height: fit-content;
                display: flex;
                flex-direction: row;
                transform: rotate( 4deg);

                .potato {
                    height: 17.5vh;
                    margin: auto;
                }

                .name {
                    margin: auto;
                    font-size: 7vh;
                    text-align: center;
                    font-family: "KCHand";
                    padding-top: 5vh;

                    &.pink {
                        color: #E56DA6;
                    }

                    &.green {
                        color: #69BFAB;
                    }
                }
            }

            .title {
                margin: auto;
                font-size: 10vh;
                text-align: center;
                font-family: 'KCHand';
                color: white;
                max-width: 75%;
                transform: translate(0, -3vh) rotate( 6deg);
            }
        }
    }

    .playerColumn {
        height: 81%;
        width: 27.5%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        margin-bottom: -1vh;
        margin-top: auto;
        transition: 1.5s;
        transform: translateX(0);
        z-index: 1;
        padding-bottom: 2vh;

        &.left {

            &.hide {
                transform: translateX(-60vh);
            }
        }

        &.right {
            &.hide {
                transform: translateX(60vh);
            }
        }
    }

    .middleColumn {
        width: 45%;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        position: relative;

        .timerSection {
            position: absolute;
            margin: auto;
            right: 0;
            left: 0;
            bottom: 3vh;
            width: fit-content;
            display: flex;
            flex-direction: row;
            transition: 2s;
            transform: translate(-4vh, 25vh);
            z-index: 5;

            .timerBg {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 2vh;
                width: 26vh;
                height: 65%;
                background-color: black;
                border-radius: 2vh;
            }

            &.showTimer {
                transform: translate(-4vh, 0vh);
            }


            .timerTitle {
                font-size: 3vh;
                font-family: "Metallophile";
                text-align: center;
            }

            .timer {
                font-size: 8vh;
                font-family: "Metallophile";
                text-align: center;
                height: -moz-fit-content;
                height: fit-content;
                margin: auto;
                color: white;
                position: absolute;
                top: 4vh;
                left: 13.5vh;
            }
        }



        .questionSection {
            width: 100%;
            height: -moz-fit-content;
            height: fit-content;
            display: flex;
            z-index: 19;
            position: relative;
            transition: 1.5s;
            transform: translateY(0);

            &.hidden {
                transform: translateY(-50vh);
            }

            .questionBox {
                width: 65%;
                height: -moz-fit-content;
                height: fit-content;
                margin: auto;
                position: absolute;
                margin: auto;
                display: flex;
                flex-direction: column;
                background-color: white;
                box-shadow: 7px 8px 16px 3px rgba(0,0,0,0.3);
                top: 13vh;
                /* bottom: 0; */
                left: 0;
                right: 0;

                .text {
                    margin: 3vh;
                    margin-left: 5vh;
                    margin-right: 5vh;
                    font-size: 3.5vh;
                    text-align: center;
                    font-family: "Metallophile";
                }

                .questionFooter {
                    width: 100%;
                    margin-bottom: auto;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }

        .penSection {
            width: 100%;
            height: 50vh;
            display: flex;
            position: relative;
            margin-top: auto;
            margin-left: auto;
            margin-right: auto;
            transition: 1s;
            transform: translateY(0);

            &.hidden {
                transform: translateY(75vh);
            }

            .penBack {
                width: 100%;
                height: 100%;
                margin: auto;
                border-radius: 1vh;
                position: relative;
            }

            .cowsSection {
                position: absolute;
                margin: auto;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                padding: 12vh;
                display: flex;

                .cowBox {
                    position: relative;
                    margin: auto;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;

                    .cowRow {
                        position: relative;
                        margin: auto;
                        height: 33%;
                        width: 100%;
                        display: flex;

                        .cow {
                            position: absolute;
                            height: 6vh;
                            margin: auto;
                            transition: 3s;
                            /*transition: all 2s linear;*/
                            &.bounce {
                                animation: BounceAnim 0.35s linear infinite;

                                &.pink {
                                    animation: PinkBounceAnim 0.5s linear infinite;
                                }
                            }

                            &.shake {
                                animation: ShakeAnim 0.25s linear infinite;
                            }

                            &.pink {
                                height: 12vh;
                                z-index: 0 !important;
                            }
                        }
                    }
                }
            }

            .penFront {
                width: 100%;
                margin: auto;
                border-radius: 1vh;
                bottom: 0;
                position: absolute;
                z-index: 19;
            }
        }
    }

    .answerSection {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;

        .background {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: black;
            opacity: 0.5;
        }

        .list {
            width: 50%;
            height: 100%;
            margin: auto;
            display: flex;
            flex-direction: column;
            z-index: 1;

            .listTitle {
                border-radius: 1vh;
                background-color: white;
                font-size: 3.75vh;
                padding: 2vh;
                margin: auto;
            }

            .answerList {
                width: 100%;
                height: 75%;
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                margin: auto;

                .answer {
                    width: 40%;
                    height: 22%;
                    border: solid 0.5vh black;
                    border-radius: 1vh;
                    background-color: white;
                    margin: auto;
                    display: flex;
                    position: relative;

                    &.selected {
                        background-color: orangered;
                    }

                    &.confirmed {
                        display: none;
                    }

                    .text {
                        margin: auto;
                        text-align: center;
                        font-size: 2vh;
                    }

                    .nameBox {
                        position: absolute;
                        border: solid 0.5vh black;
                        border-radius: 1vh;
                        left: 0;
                        right: 0;
                        bottom: -5vh;
                        margin: auto;
                        text-align: center;
                        padding: 1vh;
                        font-size: 1.75vh;
                        z-indeX: -1;
                        width: fit-content;
                        background-color: white;
                    }
                }
            }
        }
    }

    .winnersContainer {
        position: absolute;
        left: 0;
        right: 0;
        top: 5vh;
        margin: auto;
        display: flex;
        z-index: 3;
        flex-direction: column;
        width: 55%;

        .winnerTitle {
            font-size: 15vh;
            text-align: center;
            margin: auto;
            font-family: 'KCHand';
            text-shadow: 0.5vh 0.5vh 0px #e56da6;
        }

        .winnerBox {
            text-align: center;
            margin: auto;
            display: flex;
            height: fit-content;
            position: relative;
            width: 100%;
            flex-wrap: wrap;

            .winner {
                display: flex;
                width: 48%;
                height: 15vh;
                transition: 1s;
                transform: scale(0);
                margin: auto;
                padding: 2vh;
                margin-top: 2vh;

                &.show {
                    transform: scale(1);
                }

                .winnerBg {
                    width: 100%;
                    position: absolute;
                    margin: auto;
                    bottom: 0;
                    top: 0;
                    left: 0;
                    right: 0;
                    z-index: -1;
                }

                .winnerText {
                    color: white;
                    text-align: left;
                    font-family: "Metallophile";
                    margin: auto;
                    z-index: 1;
                    margin-left: 17vh;
                    text-overflow: ellipsis;
                    overflow: hidden;

                    &.large {
                        font-size: 7vh;
                    }

                    &.medium {
                        font-size: 5vh;
                    }

                    &.small {
                        font-size: 3vh;
                    }
                }

                .winPotato {
                    width: 20vh;
                    height: auto;
                    position: absolute;
                    top: -3vh;
                    left: 0;
                }
            }
        }
    }

    .startGameSection {
        width: fit-content;
        height: fit-content;
        position: absolute;
        left: 0;
        right: 0;
        top: 13vh;
        margin: auto;
        flex-direction: column;
        display: flex;
        z-index: 26;
        transition: 2s;
        transform: translateY(-100vh);

        &.center {
            /*top: 0;*/
            bottom: 0;
        }


        &.show {
            transform: translateY(0);
        }

        .choiceButton {
            width: fit-content;
            height: fit-content;
            margin: auto;
            padding-left: 3vh;
            padding-right: 3vh;
            margin-bottom: 4vh;
            font-size: 11vh;
            background-color: #E56DA6;
            border-radius: 2vh;
            font-family: 'KCHand';
            color: white;
            box-shadow: 0px 1.5vh 0px 0px #c1347e;
            user-select: initial !important;
            cursor: pointer;

            &.smaller {
                width: fit-content;
                height: fit-content;
                border-radius: 1vh;
                font-size: 7vh;
                box-shadow: 0px 1vh 0px 0px #c1347e;
            }
        }

        .skipBox {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            width: fit-content;
            justify-content: center;
            align-items: center;
            margin-left: auto;
            margin-right: auto;

            input[type="checkbox"] {
                -webkit-appearance: none;
                appearance: none;
                background-color: #E56DA6;
                margin: 0;
                font: inherit;
                width: 7.5vh;
                height: 7.5vh;
                border-radius: 2vh;
                transform: translateY(-0.075em);
                display: grid;
                place-content: center;

                &:hover {
                    background-color: #C1347E !important;
                }
            }

            input[type="checkbox"]::before {
                content: "";
                width: 5.5vh;
                height: 5.5vh;
                clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
                transform: scale(0);
                transform-origin: center;
                transition: 120ms transform ease-in-out;
                box-shadow: inset 1em 1em var(--form-control-color);
                /* Windows High Contrast Mode */
                background-color: white;
            }

            input[type="checkbox"]:checked::before {
                transform: scale(1);
            }

            label[for="checkbox"] {
                padding-left: 2vh;
                font-size: 5.5vh;
                border-radius: 2vh;
                font-family: 'Metallophile';
                color: #212529;
                margin: auto;
            }
        }
    }

    .playAgainSection {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;
        margin-bottom: unset;
        position: absolute;
        transform: translateY(100vh);
        transition: 1s;
        z-index: 25;

        &.show {
            transform: translateY(0);
        }

        .buttonsSection {
            width: 35%;
            height: fit-content;
            margin: auto;
            display: flex;
            flex-direction: column;
            margin-top: 40vh;
            margin-left: 15%;

            .choiceButton {
                width: fit-content;
                height: fit-content;
                margin: auto;
                padding-left: 2.5vh;
                padding-right: 2.5vh;
                margin-bottom: 7vh;
                font-size: 10vh;
                background-color: #E56DA6;
                border-radius: 2vh;
                font-family: 'KCHand';
                color: white;
                box-shadow: 0px 1.5vh 0px 0px #c1347e;
                user-select: initial !important;
                cursor: pointer;

                &.smaller {
                    width: fit-content;
                    height: fit-content;
                    border-radius: 1vh;
                    font-size: 5vh;
                    box-shadow: 0px 1vh 0px 0px #c1347e;
                }
            }
        }

        .leaderboardSection {
            width: 50%;
            height: 75%;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            margin-top: auto;
            justify-content: center;
            align-items: start;
            padding-left: 5vh;
            margin-bottom: 5vh;

            .boardRow {
                width: 70%;
                height: fit-content;
                justify-content: center;
                align-items: center;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                border-radius: 1.25vh;
                position: relative;
                margin-bottom: 1.4vh;
                margin-top: 1.4vh;
                background-color: white;

                .numberBox {
                    position: relative;
                    color: white;
                    background-color: #534197;
                    width: 10%;
                    min-height: 100%;
                    display: flex;
                    border-top-left-radius: inherit;
                    border-bottom-left-radius: inherit;

                    .positionText {
                        text-align: center;
                        /*line-height: 1;*/
                        font-family: "KCHand";
                        font-size: 3vh;
                        margin: auto;
                    }

                    .cowHead {
                        height: 8vh;
                        width: auto;
                        margin: auto;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                    }
                }

                .nameBox {
                    text-align: start;
                    /*line-height: 1;*/
                    font-family: "KCHand";
                    width: 60%;
                    font-size: 3vh;
                    padding-left: 1vh;
                }

                .scoreBox {
                    width: 30%;
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;

                    .text {
                        font-family: "KCHand";
                        font-size: 3vh;

                        &.smaller {
                            font-size: 2vh;
                            padding-bottom: 0.3vh;
                            padding-left: 0.5vh;
                            margin-top: auto;
                        }
                    }
                }

                .potato {
                    width: 10vh;
                    height: auto;
                    margin: auto;
                    position: absolute;
                    right: -5.5%;
                    top: -3.5vh;
                }
            }
        }
    }

    @keyframes BounceAnim {
        0%, 100% {
            top: 0;
        }

        50% {
            top: 15%;
        }
    }

    @keyframes PinkBounceAnim {
        0%, 100% {
            top: -100%;
        }

        50% {
            top: -75%;
        }
    }

    @keyframes ShakeAnim {
        0% {
            transform: rotate(0deg);
        }

        33% {
            transform: rotate(20deg);
        }

        66% {
            transform: rotate(-20deg);
        }

        100% {
            transform: rotate(0deg);
        }
    }
}
