.defaultSection {
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;

    .logo {
        height: 7vh;
        margin-left: 3vh;
        margin-right: auto;
        margin-top: 1vh;
    }

    .playerSection {
        height: fit-content;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        height: fit-content;

        .potato {
            width: 50%;
            margin-left: auto;
            margin-right: auto;
            cursor: pointer;
        }

        .text {
            font-size: 50px;
            font-family: "KCHand";
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            color: #E56DA6;

            &.score {
                font-size: 30px;
                font-family: 'Metallophile';
            }
        }
    }

    .buttons {
        display: flex;
        flex-direction: column;

        .skipBox {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            width: fit-content;
            justify-content: center;
            align-items: center;
            margin-left: auto;
            margin-right: auto;
            margin-top: 2vh;

            input[type="checkbox"] {
                -webkit-appearance: none;
                appearance: none;
                background-color: #E56DA6;
                margin: 0;
                font: inherit;
                width: 4vh;
                height: 4vh;
                border-radius: 1vh;
                transform: translateY(-0.075em);
                display: grid;
                place-content: center;

                &:hover {
                    background-color: #C1347E !important;
                }
            }

            input[type="checkbox"]::before {
                content: "";
                width: 2vh;
                height: 2vh;
                clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
                transform: scale(0);
                transform-origin: center;
                transition: 120ms transform ease-in-out;
                box-shadow: inset 1em 1em var(--form-control-color);
                /* Windows High Contrast Mode */
                background-color: white;
            }

            input[type="checkbox"]:checked::before {
                transform: scale(1);
            }

            label[for="checkbox"] {
                padding-left: 1vh;
                font-size: 3vh;
                font-family: 'Metallophile';
                color: #E56DA6;
                z-index: 1;
                margin-top: auto;
                margin-bottom: auto;
            }
        }
    }

    .button {
        font-size: 3vh;
        text-align: center;
        color: #5D5D5D;
        height: fit-content;
        width: fit-content;
        padding: 0.25vh;
        padding-left: 3vh;
        padding-right: 3vh;
        margin-left: auto;
        margin-right: auto;
        margin-top: 3vh;
        border-radius: 1vh;
        font-family: 'Metallophile';
        user-select: inherit !important;
        cursor: pointer;
        transform: scale(1);
        color: white;

        background-color: #E56DA6;
        box-shadow: 0px 1vh 0px 0px #c1347e;
        font-family: 'KCHand';

        &:active {
            transform: scale(1.2);
        }
    }

    .contentSection {
        width: 90%;
        height: fit-content;
        margin-left: auto;
        margin-right: auto;
        margin-top: 2%;
        display: flex;
        flex-direction: column;
        height: 30%;

        .title {
            font-size: 40px;
            font-family: "KCHand";
            text-align: center;
            margin: auto;
            margin-bottom: unset;
            color: #E56DA6;
        }

        .content {
            font-size: 25px;
            font-family: "Metallophile";
            text-align: center;
            color: #E56DA6;
            margin: auto;
            margin-top: unset;
        }
    }
}
