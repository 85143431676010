@font-face {
    font-family: "DearType";
    src: url('/fonts/DearType-HelloHeadline.otf') format('opentype');
}
@font-face {
    font-family: "KCHand";
    src: url(/fonts/KCIllHand.otf) format('opentype');
}

@font-face {
    font-family: "Metallophile";
    src: url('/fonts/MarkSimonson-MetallophileSp8Medium.otf') format('opentype');
}